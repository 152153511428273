.social-links {
    margin-top: 10px !important;
    margin-bottom: 0;
    margin-right: 0 !important;
    padding: 0 2px !important;
}
.subscribe_buttons {
  > a {
    color: black;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  > div {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
.homepage_thumbnail {
    width: 250px;
    height: 250px;
}
.hompage_episode_description {
    padding-left: 20px;
}
.homepage_episode_row {
    padding-bottom: 20px;
    padding-top: 20px;
}
.jumbotron-host {
    margin: auto;
    display: block;
}
.grid_container {
  padding-top: 20px;
}

.grid_episode_col {
  margin-bottom: 20px;
}

.grid_episode_detail {
  background-color: #000;
  padding: 10px;
  color: $castanet-middle-container-bg;
}

.grid_episode_title {
  color: $castanet-middle-container-bg;
  > h3 {
    text-transform: capitalize;
  }
}
.grid_episode_title:hover {
  text-decoration: none;
  color: $castanet-middle-container-bg;
}

.row_latest_episode_title {
  > h3 {
    text-transform: capitalize;
  }
}
.row_latest_episode_title:hover {
  text-decoration: none;
  color: $castanet-main-container-bg;
}

.sidebar_general {
    background-color: $castanet-sidebar-bg;
    // border-radius:15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 40px;
}
.sidebar_headline {
    color: $castanet-middle-container-bg;
    font-family: 'Lato', sans-serif;
    margin-top: 0;
}
// html body {
//   background-color: $castanet-body-bg;
// }
.middle_container {
    background-color: $castanet-middle-container-bg;
}
.hero_container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
}
.main_container {
    background-color: $castanet-main-container-bg;
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0;
}
.bottom_container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.navbar_footer {
    border-radius: 0px!important;
}
.footer_copyright {
  > a {
    color: $castanet-middle-container-bg;
  }
    padding-right: 40px;
    padding-top: 10px;
    color: $castanet-middle-container-bg
}
.sidebar_col {
    padding-top: 40px !important;
    padding-left: 40px;
    padding-right: 40px !important;
}
.sidebar_content {
    background-color: #fff;
    padding: 20px;
}


.person_row {
  padding-bottom: 20px;
}

.youtube_row {
  padding-top: 20px;
  padding-bottom: 20px;
}

// Guest page styles

.guest_page_episode_link {
  text-transform: capitalize;
}

.guest_episode_list {
  padding-bottom: 20px;
}

.guest_social_icons {
  padding-top: 10px;
  padding-bottom: 10px;
}
#mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica,Arial,sans-serif;
}
#mc_embed_signup form {
    display: block;
    position: relative;
    text-align: left;
    padding: 10px 0 10px 3%;
}
#mc_embed_signup h2 {
    font-weight: bold;
    padding: 0;
    margin: 15px 0;
    font-size: 1.4em;
}
#mc_embed_signup input {
    border: 1px solid #999;
    -webkit-appearance: none;
}
#mc_embed_signup input[type=checkbox] {
    -webkit-appearance: checkbox;
}
#mc_embed_signup input[type=radio] {
    -webkit-appearance: radio;
}
#mc_embed_signup input:focus {
    border-color: #333;
}
#mc_embed_signup .button {
    clear: both;
    background-color: #aaa;
    border: 0 none;
    border-radius: 4px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: auto;
    transition: all 0.23s ease-in-out 0s;
}
#mc_embed_signup .button:hover {
    background-color: #777;
}
#mc_embed_signup .small-meta {
    font-size: 11px;
}
#mc_embed_signup .nowrap {
    white-space: nowrap;
}
#mc_embed_signup .clear {
    clear: none;
    display: inline;
}
#mc_embed_signup label {
    display: block;
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: bold;
}
#mc_embed_signup input.email {
    font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
    font-size: 15px;
    display: block;
    padding: 0 0.4em;
    margin: 0 4% 10px 0;
    min-height: 32px;
    width: 58%;
    min-width: 130px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
#mc_embed_signup input.button {
    display: block;
    width: 35%;
    margin: 0 0 10px;
    min-width: 90px;
}
#mc_embed_signup div#mce-responses {
    float: left;
    top: -1.4em;
    padding: 0 0.5em;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    clear: both;
}
#mc_embed_signup div.response {
    margin: 1em 0;
    padding: 1em 0.5em 0.5em 0;
    font-weight: bold;
    float: left;
    top: -1.5em;
    z-index: 1;
    width: 80%;
}
#mc_embed_signup #mce-error-response {
    display: none;
}
#mc_embed_signup #mce-success-response {
    color: #529214;
    display: none;
}
#mc_embed_signup label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 1.05em;
    text-align: left;
    padding: 0.5em 0;
}

.episode_image {
  padding-bottom: 20px;
}

.player_row {
  padding-bottom: 15px;
  padding-top: 15px;
}

body {
    padding-top: 70px;
    background-color: $body-bg;
}

a {
  color: $body-bg;
}

//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {

  > ul {
    margin-top: 20px;
  }

  > li {
    > a,
    > span {
      color: $pagination-color;
      background-color: $pagination-bg;
      border: 1px solid $pagination-border;
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      color: $pagination-active-color;
      background-color: $pagination-active-bg !important;
      border-color: $pagination-active-border !important;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: $pagination-disabled-color;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border;
    }
  }
}


// audio player

.audiojs {

    height: 22px;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    background: #404040;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #444), color-stop(0.5, #555), color-stop(0.51, #444), color-stop(1, #444));
    background-image: -moz-linear-gradient(center top, #444 0%, #555 50%, #444 51%, #444 100%);
    -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
}

.audiojs .play-pause {
    width: 20px;
    height: 20px;
    padding: 0 8px 0 0;
}

.audiojs p {
    width: 25px;
    height: 20px;
    margin: -3px 0 0 -1px;
}

.audiojs .scrubber {
    background: #fff;
    width: 310px;
    // height: 10px;
    // margin: 5px;
}

.audiojs .progress {
    height: 100%;
    width: 0;
    background: #ccc;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ccc), color-stop(0.5, #ddd), color-stop(0.51, #ccc), color-stop(1, #ccc));
    background-image: -moz-linear-gradient(center top, #ccc 0%, #ddd 50%, #ccc 51%, #ccc 100%);
}

.audiojs .loaded {
    height: 50%;
    background: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #222), color-stop(0.5, #333), color-stop(0.51, #222), color-stop(1, #222));
    background-image: -moz-linear-gradient(center top, #222 0%, #333 50%, #222 51%, #222 100%);
}

.audiojs .time {
    float: right;
    height: 25px;
    line-height: 25px;
}

.audiojs .error-message {
    height: 24px;
    line-height: 24px;
}

.track-details {
    clear: both;
    height: 20px;
    width: 448px;
    padding: 1px 6px;
    background: #eee;
    color: #222;
    font-family: monospace;
    font-size: 11px;
    line-height: 20px;
    -webkit-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.15);
}

.track-details:before {
    content: '♬ ';
}

.track-details em {
    font-style: normal;
    color: #999;
}

.audiojs {
    display: table;
    width: 100%;
    height: 34px;

    .play-pause, .scrubber, .time {
        display: table-cell;
        float: none;
        height: auto;
        vertical-align: middle;
    }

    .play-pause {
        padding: 0 6px;

        > p {
            margin: 0;
            padding: 0;
            height: 26px;
        }
    }

    .scrubber {
        width: 99%;
        margin: 0;
        padding: 0;

        > div {
            position: relative;
            margin: 0;

            &.progress {
                z-index: 2;
            }

            &.loaded {
                margin-top: -14px;
                z-index: 1;
            }
        }
    }

    .time {
        line-height: normal;
        margin: 0;
        padding: 0 12px;
        width: auto;
    }
}
